import {
    LOADING,
    SET_USER_SESSION,
    SET_PARAMETERS,
    SET_DATE_PARAMETERS,
    REMOVE_PARAMETER,
    CLEAR_PARAMETER,
} from '../actions';

// TODO GLOBAL
export const initialState = {
    parameters: {
        materials: {},
        contracts: {},
        orders: {},
        placeholderMaterials: {},
        serialNumbers: {},
        systems: {},
        systemApplications: {},
        licenses: {},
        dataQualitySapOrders: {},
        hwsSnsTrail: {}
    },
};

// TODO GLOBAL
export function download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
};

// TODO GLOBAL
export function downloadBulkResult(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

// TODO GLOBAL
export const getNestedValueFromString = (from, ...selectors) =>
    [...selectors].map(s =>
        s
            .replace(/\[([^\[\]]*)\]/g, '.$1.')
            .split('.')
            .filter(t => t !== '')
            .reduce((prev, cur) => prev && prev[cur], from)
    );

function rootReducer(state = initialState, action) {
    if (action?.type !== "SET_USER_SESSION") {
        console.log("reducer action", action);
    };
    // state.loading = false;
    if (state.errors) {
        delete state.errors;
    };
    state.errors = action.errors;
    switch (action.type) {
        case LOADING:
            state = Object.assign({}, state, { loading: action.loading });
            return state;

        case SET_USER_SESSION:
            state = Object.assign({}, state, { userSession: action.userSession });
            return state;

        case SET_PARAMETERS:
            console.log('SET_PARAMETERS', state.parameters)
            let setFamily = Object.keys(action.data)[0];
            let setTypes = Object.keys(action.data[setFamily]);

            for (let type of setTypes) {
                if (!state.parameters[setFamily]?.[type]) {
                    state.parameters[setFamily][type] = [];
                };
                let newState = Object.assign({}, state);
                newState.parameters[setFamily][type] = newState.parameters[setFamily][type].concat(action.data[setFamily][type]);
                state = Object.assign({}, newState);
            };
            return state;

        case SET_DATE_PARAMETERS:
            console.log('SET_DATE_PARAMETERS', state.parameters)
            let setDateFamily = Object.keys(action.data)[0];
            let setDateTypes = Object.keys(action.data[setDateFamily]);

            for (let type of setDateTypes) {
                let newState = Object.assign({}, state);
                if (action.data[setDateFamily][type] === ''){
                    delete newState?.parameters[setDateFamily][type];
                }
                else {
                    newState.parameters[setDateFamily][type] = action.data[setDateFamily][type];
                }
                state = Object.assign({}, newState);
            };
            return state;
        case REMOVE_PARAMETER:
            let removeFamily = Object.keys(action.data)[0];
            let removeType = Object.keys(action.data[removeFamily])[0];
            let removeArray = action.data[removeFamily][removeType];
            let newState = Object.assign({}, state);
            let newArray = newState.parameters[removeFamily][removeType].filter(e => e != removeArray[0]);
            newState.parameters[removeFamily][removeType] = newArray;
            state = Object.assign({}, newState);
            return state;
        case CLEAR_PARAMETER:
            let clearFamily = Object.keys(action.data)[0];
            let clearType = action.data[clearFamily];
            let newClearState = Object.assign({}, state);
            delete newClearState?.parameters[clearFamily][clearType];
            state = Object.assign({}, newClearState);
            return state;
        default:
            return state;
    }
}

export default rootReducer