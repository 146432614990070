import {
    GET_MATERIALS,
    GET_MATERIAL_DETAILS,
    FILTER_MATERIALS,
    SORT_MATERIALS,
    GET_MATERIALS_BULK,
    getMaterials,
    getMaterialDetails,
    EXPORT_MATERIALS_TO_CSV,
    EXPORT_MATERIALS_CONTRACTS_TO_CSV,
    GET_CANDIDATE_MATERIALS,
    GET_CHILD
} from '../actions';
import { LOADING, ERROR } from '../../global/actions'
import { EXPORT_CONTRACT_MATERIALS_TO_CSV } from '../../contract/actions'

import { Auth } from 'aws-amplify';

import { API, graphqlOperation } from '@aws-amplify/api'
import config from '../../../aws-exports.js'
import * as queries from '../../../graphql/queries.js';
import { restfulResources, getFromServer, getFromGraphQlApi, parseDynamoResult } from '../../global/middleware';
import { customFetch } from '../../../utils.js';



API.configure(config)



async function getMaterialDetailsFromServer(action) {
    let questObject = {};
    questObject.params = {
        serial_number: action.data?.serial_number,
        show_entitlements: true,
        show_parents: true,
        show_children: true
    }
    if (action?.data?.placeholder === true) {
        questObject.resource = 'placeholderMaterials';
    } else {
        questObject.resource = 'materials';
    }

    let getResult = await getFromServer(questObject);
    if (getResult.responseCode < 202 && getResult !== 0) {

        let result = getResult.results;
        let getDynamoResult;
        // Getting extra details from Dynamo
        questObject = {
            resource: 'serialNumber',
            params: { serial_number: action.data?.serial_number }
        };
        getDynamoResult = await getFromServer(questObject);
        if (getDynamoResult?.results?.length > 0) {
            result.serialNumberReference = parseDynamoResult(getDynamoResult.results[0]);
        };
        // Getting order details from Dynamo
        if (action.data?.order_id && action.data?.order_line) {
            // Replacing last two characters, as business logic requires them but they cannot be searched on
            let parsedOrderLine = action.data.order_line;
            if (!(parsedOrderLine?.endsWith("00") || parsedOrderLine?.endsWith("50"))) {
                parsedOrderLine = parsedOrderLine?.slice(0, -2) + "00";
            }
            questObject = {
                resource: 'order',
                params: { order_id: action.data.order_id, order_line: parsedOrderLine }
            };
            getDynamoResult = await getFromServer(questObject);
            if (getDynamoResult?.results?.length > 0) {
                result.order = getDynamoResult.results[0];
                // Displaying original order line
                result.order.order_line = action.data.order_line;
            };
        };
        return result;
    } else {
        throw new Error()
    };
};

let questObject;
let seEngineParameters;
let dataPipelineParameters;
let seEngineContractParameters;
let getResult;
let getContractsResult;
let contractReferences;
let headers;
let materialsToExport;

export function materialMiddleware({ dispatch }) {
    return function (next) {
        return async function (action) {
            //TODO: Error handling
            switch (action.type) {
                case GET_MATERIALS:
                    dispatch({ type: LOADING, loading: true });
                    console.log("action GET_MATERIALS", action.data)

                    questObject = {};
                    questObject.params = Object.assign({}, action.data);
                    questObject.resource = 'materials';

                    seEngineParameters = { params: {} };
                    seEngineParameters.resource = 'materials';
                    seEngineParameters.params.serial_number = questObject.params.serial_number;
                    seEngineParameters.params.business_partner = questObject.params.business_partner;
                    seEngineParameters.params.sold_to = questObject.params.sold_to;
                    seEngineParameters.params.order = questObject.params.order;
                    seEngineParameters.params.end_user = questObject.params.end_user;
                    seEngineParameters.params.support_partner = questObject.params.support_partner;
                    seEngineParameters.params.creation_date = questObject.params.creation_date;
                    seEngineParameters.params.creation_date_from = questObject.params.creation_date_from;
                    seEngineParameters.params.creation_date_to = questObject.params.creation_date_to;

                    // If objects is undefined, delete it
                    Object.keys(seEngineParameters.params).forEach(key => {
                        if (seEngineParameters.params[key]?.length < 1 || !seEngineParameters.params[key]) {
                            delete seEngineParameters.params[key];
                        }
                    });

                    // TODO refactor, split asset/dynamo/contracts queries
                    dataPipelineParameters = { params: {} };
                    dataPipelineParameters.params.bundle_reference = questObject.params.bundle_reference;
                    Object.keys(dataPipelineParameters.params).forEach(key => {
                        if (dataPipelineParameters.params[key]?.length < 1) {
                            delete dataPipelineParameters.params[key];
                        };
                    });

                    seEngineContractParameters = { params: {} };
                    seEngineContractParameters.resource = 'contracts';
                    seEngineContractParameters.params.contract_reference = questObject.params.contract_reference;
                    Object.keys(seEngineContractParameters.params).forEach(key => {
                        if (seEngineContractParameters.params[key]?.length < 1) {
                            delete seEngineContractParameters.params[key];
                        };
                    });

                    action.results = [];

                    if (Object.keys(seEngineParameters.params).length > 0) {
                        seEngineParameters.params.show_parents = true;
                        seEngineParameters.params.show_children = true;
                        let getResult = await getFromServer(seEngineParameters);
                        if (getResult?.responseCode < 202 && getResult !== 0) {
                            action.results = action.results.concat(getResult.results);
                            // Remove trailing 0s from dates
                            // TODO refactor mutualize
                            for (let i in action.results) {
                                let newResult = action.results[i];
                                if (newResult?.creation_date && newResult.modification_date) {
                                    newResult.creation_date = newResult?.creation_date?.slice(0, -3);
                                    newResult.modification_date = newResult?.modification_date?.slice(0, -3);
                                }
                                action.results[i] = newResult;
                            };
                        } else {
                            action.errors = { responseCode: getResult?.responseCode };
                            console.log('action.errors', action.errors, 'getResult', getResult);
                        };
                    };
                    //TODO delete this call => GraphQL get_contracts
                    if (Object.keys(seEngineContractParameters.params).length > 0 && seEngineContractParameters.params?.contract_reference) {
                        seEngineContractParameters.params.show_entitlements = true;
                        seEngineContractParameters.params.show_children = true;
                        getContractsResult = await getFromServer(seEngineContractParameters);
                        contractReferences = [];
                        if (getContractsResult?.results?.length > 0) {
                            let detailedContractQuestObject = {
                                params: {
                                    show_entitlements: true,
                                    show_parents: true
                                }
                            }
                            for (let contract of getContractsResult.results) {
                                contractReferences.push(contract.contract_reference)
                            };
                            detailedContractQuestObject.resource = 'contracts';
                            detailedContractQuestObject.params.contract_reference = contractReferences
                            let detailedContractResult = await getFromServer(detailedContractQuestObject)

                            if (detailedContractResult.responseCode < 202 && detailedContractResult !== 0) {
                                let materialsResult = [];
                                // Remove trailing 0s from dates
                                for (let i in detailedContractResult?.results) {
                                    let contract = detailedContractResult.results[i];
                                    for (let j in contract?.entitles) {
                                        let newMaterial = contract?.entitles[j]
                                        if (newMaterial.type === 'material') {
                                            if (newMaterial.creation_date && newMaterial.modification_date) {
                                                newMaterial.creation_date = newMaterial?.creation_date?.slice(0, -3);
                                                newMaterial.modification_date = newMaterial?.modification_date?.slice(0, -3);
                                            }
                                            materialsResult[j] = newMaterial;
                                        };
                                    };
                                };
                                action.results = action.results.concat(materialsResult);
                            } else {
                                action.errors = { responseCode: detailedContractResult.responseCode };
                                console.log('action.errors', action.errors, 'getResult', detailedContractResult);
                            };
                        }

                    };

                    // TODO export to DynamoDb redux
                    if (Object.keys(dataPipelineParameters.params).length > 0 && dataPipelineParameters.params?.bundle_reference) {
                        questObject = {
                            resource: 'serialNumber',
                            params: { GSI1PK: dataPipelineParameters.params.bundle_reference }
                        };
                        let getResult = await getFromServer(questObject);
                        if (getResult.responseCode < 202 && getResult !== 0) {
                            let detailedQuestObject = {
                                resource: 'materials',
                                params: {
                                    serial_number: []
                                }
                            };
                            if (getResult.results?.length > 0) {
                                for (const result of getResult.results) {
                                    detailedQuestObject.params.serial_number.push(result.serial_number?.S);
                                };
                                let newResult = await getFromServer(detailedQuestObject);
                                action.results = action.results.concat(newResult?.results);
                            };


                            // Remove trailing 0s from dates
                            for (let i in action.results) {
                                let newResult = action.results[i];
                                if (newResult?.creation_date && newResult.modification_date) {
                                    newResult.creation_date = newResult?.creation_date?.slice(0, -3);
                                    newResult.modification_date = newResult?.modification_date?.slice(0, -3);
                                }
                                action.results[i] = newResult;
                            };
                        } else {
                            action.errors = { responseCode: getResult.responseCode };
                            console.log('action.errors', action.errors, 'getResult', getResult);
                        };
                    }
                    break;

                case GET_MATERIAL_DETAILS:
                    try {
                        let materialDetails = await getMaterialDetailsFromServer(action);
                        action.results = materialDetails;
                    } catch (err) {
                        dispatch({ type: ERROR });
                    };
                    break;
                case FILTER_MATERIALS:
                    break;

                case EXPORT_MATERIALS_TO_CSV:
                    headers = [
                        "serial_number",
                        "status",
                        "inactive_reason",
                        "business_partner",
                        "support_partner",
                        "end_user",
                        "children"
                    ];

                    materialsToExport = headers.join(",") + "\n";

                    for (let material of action?.materials) {
                        let newMaterial;
                        try {
                            newMaterial = await getMaterialDetailsFromServer({ data: { serial_number: material.serial_number } });
                        } catch (err) {
                            dispatch({ type: ERROR });
                        };

                        let newMaterialArray = [
                            material?.serial_number,
                            material?.status,
                            material?.inactive_reason,
                            material?.business_partner_company_id,
                            material?.support_partner_company_id,
                            material?.end_user_company_id,
                            '"' + newMaterial[0]?.children + '"'
                        ];

                        materialsToExport = materialsToExport + newMaterialArray.join(",") + "\n";

                    };
                    console.log("materialsToExport", materialsToExport)
                    action.CSVMaterials = materialsToExport;
                    break;
                case EXPORT_MATERIALS_CONTRACTS_TO_CSV:
                    headers = [
                        "serialNumber",
                        "partNumber",
                        "salesOrderId",
                        "invoiceLine",
                        "entStartDate",
                        "entEndDate",
                        "entType",
                        "included",
                        "serviceStartDate",
                        "serviceEndDate",
                        "endCustomerCompanyName",
                        "endCustomerCompanyId"
                    ];

                    let contractsToExport = headers.join(",") + "\n";
                    let serialNumbers = [];
                    for (let material of action?.data?.materials) {
                        serialNumbers.push(material?.serial_number);
                    };

                    let newMaterials = [];
                    let graphResult;

                    if (action?.data?.type === "placeholder") {
                        try {
                            newMaterials = await getMaterialDetailsFromServer({ data: { serial_number: serialNumbers } });
                            newMaterials = newMaterials.concat(action?.data?.materials);
                        } catch (err) {
                            dispatch({ type: ERROR });
                        }
                    } else {
                        graphResult = await getFromGraphQlApi(queries.get_materials_entitlements, { serial_number: serialNumbers });
                        newMaterials = graphResult?.data?.get_materials;
                    };
                    if (newMaterials?.length > 0) {
                        for (let material of newMaterials) {
                            if (material?.entitlements && material?.entitlements?.length > 0) {
                                for (let entitlement of material?.entitlements) {
                                    if (
                                        !entitlement?.contract_reference?.startsWith("DOA-") &&
                                        !entitlement?.contract_reference?.startsWith("CpltServ-") &&
                                        !entitlement?.contract_reference?.startsWith("StandardHWS-") &&
                                        !entitlement?.contract_reference?.startsWith("EPP_")
                                    ) {
                                        let splittedContractReference = entitlement?.contract?.contract_reference?.split('_');
                                        let newEntitlementArray = [
                                            material?.serial_number,
                                            material?.part_number,
                                            splittedContractReference[0],
                                            splittedContractReference[1],
                                            entitlement?.start_date,
                                            entitlement?.end_date,
                                            entitlement?.contract?.contract_type,
                                            entitlement?.included,
                                            entitlement?.entitlement_start_date,
                                            entitlement?.entitlement_end_date,
                                            null,
                                            material?.end_user?.company_id,
                                        ];
                                        contractsToExport = contractsToExport + newEntitlementArray.join(",") + "\n";
                                    };
                                };
                            };
                        };
                    };
                    action.CSVMaterials = contractsToExport;
                    break;
                case EXPORT_CONTRACT_MATERIALS_TO_CSV:
                    let materials = action.data?.contract?.entitlements
                    console.log('materials', materials)

                    headers = [
                        "serialNumber",
                        "contractReference",
                        "creationDate",
                        "entitlementStartDate",
                        "entitlementEndDate",
                        "orderId",
                        "orderLine",
                        "partNumber",
                        "status",
                        "type"
                    ];

                    materialsToExport = headers.join(",") + "\n";

                    for (let material of materials) {
                        let newEntitlementArray = [
                            material?.serial_number,
                            material?.contract_reference,
                            material?.creation_date,
                            material?.entitlement_start_date,
                            material?.entitlement_end_date,
                            material?.order?.order_id,
                            material?.order?.order_line,
                            material?.part_number,
                            material?.status,
                            material?.__typename
                        ];
                        materialsToExport = materialsToExport + newEntitlementArray.join(",") + "\n";
                    }

                    action.CSVMaterials = materialsToExport;
                    break;
                case SORT_MATERIALS:
                    break;

                case GET_MATERIALS_BULK:
                    questObject = {};
                    questObject.params = Object.assign({}, action.data);
                    questObject.resource = 'bulkMaterials';

                    let bulkResult = await getFromServer(questObject);
                    let url = bulkResult?.url;

                    let bulkResponse = await customFetch(url, 10, 3000)
                    .catch(err => {
                        console.log('Error:', err);
                        return null; 
                    })
                    .then(async response => {
                        if (response) {
                            const text = await response.text();
                            return {
                                response,
                                text
                            };
                        }
                        return null;
                    });
                    if (bulkResponse){
                        let jsonParsedResponse = JSON.parse(bulkResponse.text);
                        for (let entry of jsonParsedResponse) {
                            entry.business_partner = entry?.business_partner?.company_id;
                            entry.end_user = entry?.end_user?.company_id;
                            entry.order_id = entry?.order?.order_id;
                            entry.order_line = entry?.order?.order_line;
                            entry.purchase_order = entry?.order?.purchase_order;
                            delete entry.order;
                            entry.sold_to = entry?.sold_to?.applicant_id;
                            entry.support_partner = entry?.support_partner?.company_id;
                        };
                        action.bulkResponse = jsonParsedResponse;
                    } else {
                        action.bulkResponse = null;
                        document.cookie = `error=timeout; path=/; max-age=1`;
                    }

                    break;
                // TODO look why 2 calls
                case GET_CHILD:
                    let getResult = await dispatch(getMaterials({ serial_number: action.data.serial_number }));
                    let childMaterial = getResult.results[0];
                    let detailedChild = await dispatch(getMaterialDetails(childMaterial.serial_number, childMaterial?.order?.order_id, childMaterial?.order?.order_line));
                    action.detailedChild = detailedChild;
                    break;


                case GET_CANDIDATE_MATERIALS:
                    questObject = {};
                    questObject.params = Object.assign({}, action.data);
                    questObject.resource = 'materials';
                    console.log('questObject candidates', questObject)

                    let potentialCandidates = await getFromServer(questObject);
                    let filteredCandidates = [];
                    for (let i in potentialCandidates?.results) {
                        let candidate = potentialCandidates?.results[i]
                        console.log('candidate', candidate)
                        if (candidate?.order?.order_line?.endsWith('20')) {
                            filteredCandidates.push(candidate);
                        };
                    };
                    action.candidateMaterials = filteredCandidates;
                    break;

            };
            return next(action);
        }
    }
}