import {
    GET_ORDERS,
    FILTER_ORDERS,
    SORT_ORDERS,
    GET_SERIAL_NUMBERS,
    SORT_SERIAL_NUMBERS,
    FILTER_SERIAL_NUMBERS,
    EXPORT_SERIAL_NUMBERS_TO_CSV,
    EXPORT_ORDERS_TO_CSV,
    GET_DATA_QUALITY_SAP_ORDERS,
    SORT_DATA_QUALITY_SAP_ORDERS,
    FILTER_DATA_QUALITY_SAP_ORDERS,
    EXPORT_DATA_QUALITY_SAP_ORDERS_TO_CSV,
    GET_DATA_QUALITY_SAP_ORDERS_BULK,
    SORT_HWS_SNS_TRAIL,
    FILTER_HWS_SNS_TRAIL,
    GET_HWS_SNS_TRAIL, GET_HWS_SNS_TRAIL_BULK, EXPORT_HWS_SWAP_SNS_TO_CSV
} from '../actions';
import { LOADING } from '../../global/actions'

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import Papa from 'papaparse';

// TODO GLOBAL
const initialState = {
    parameters: {
        materials: {},
        contracts: {},
        orders: {},
        placeholderMaterials: {},
        serialNumbers: {},
        systems: {},
        systemApplications: {},
        licenses: {},
        dataQualitySapOrders: {},
        hwsSnsTrail: {}
    },
};

// TODO GLOBAL
function download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
};

// TODO GLOBAL
function downloadBulkResult(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

// TODO GLOBAL
const getNestedValueFromString = (from, ...selectors) =>
    [...selectors].map(s =>
        s
            .replace(/\[([^\[\]]*)\]/g, '.$1.')
            .split('.')
            .filter(t => t !== '')
            .reduce((prev, cur) => prev && prev[cur], from)
    );

function dynamoReducer(state = initialState, action) {
    if (action?.type !== "SET_USER_SESSION") {
        console.log("reducer action", action);
    };
    switch (action.type) {
        case LOADING:
            break;
        default:
            state.loading = false;
            break;
    };
    if (state.errors) {
        delete state.errors;
    };
    state.errors = action.errors;
    switch (action.type) {

        case FILTER_ORDERS:
            if (!state.orders) {
                state.orders = [];
            };
            let filterOrderParams = action.data;
            let filteredOrders = [];

            let newOrders = state.orders.filter(c => {
                if (filterOrderParams?.orderId?.length > 0) {
                    for (let orderId of filterOrderParams?.orderId) {
                        if (!c?.order_id.startsWith(orderId)) {
                            return false;
                        };
                    };
                }
                if (filterOrderParams?.orderLine?.length > 0) {
                    for (let orderLine of filterOrderParams?.orderLine) {
                        if (!c?.order_line.startsWith(orderLine)) {
                            return false;
                        };
                    };
                };
                return true
            });
            filteredOrders = filteredOrders.concat(newOrders)

            state = Object.assign({}, state, { filteredOrders });
            return state;

        case SORT_SERIAL_NUMBERS:
            const serialNumbersParam = action.data.sortParameter;
            const serialNumbersDirection = action.data.direction;

            // This makes a direct modification to the state, so no Object.assign is required. Somehow.
            state?.filteredSerialNumbers?.sort(function (a, b) {
                var textA = getNestedValueFromString(a, serialNumbersParam).toString()?.toUpperCase();
                var textB = getNestedValueFromString(b, serialNumbersParam).toString()?.toUpperCase();
                if (serialNumbersDirection === 'ascending') {
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                } else {
                    return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
                };
            });
            return state;

        case SORT_ORDERS:
            const orderParam = action.data.sortParameter;
            const orderDirection = action.data.direction;

            // This makes a direct modification to the state, so no Object.assign is required. Somehow.
            state?.filteredOrders?.sort(function (a, b) {
                var textA = getNestedValueFromString(a, orderParam).toString()?.toUpperCase();
                var textB = getNestedValueFromString(b, orderParam).toString()?.toUpperCase();
                if (orderDirection === 'ascending') {
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                } else {
                    return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
                };
            });
            return state;
        case SORT_DATA_QUALITY_SAP_ORDERS:
            const dataQualitySapOrderParam = action.data.sortParameter;
            const dataQualitySapOrderDirection = action.data.direction;

            // This makes a direct modification to the state, so no Object.assign is required. Somehow.
            state?.filteredDataQualitySapOrders?.sort(function (a, b) {
                var textA = getNestedValueFromString(a, dataQualitySapOrderParam).toString()?.toUpperCase();
                var textB = getNestedValueFromString(b, dataQualitySapOrderParam).toString()?.toUpperCase();
                if (dataQualitySapOrderDirection === 'ascending') {
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                } else {
                    return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
                };
            });
            return state;
        case SORT_HWS_SNS_TRAIL:
            const hwsSnsTrailParam = action.data.sortParameter;
            const hwsSnsTrailDirection = action.data.direction;

            // This makes a direct modification to the state, so no Object.assign is required. Somehow.
            state?.filteredHwsSnsTrail?.sort(function (a, b) {
                var textA = getNestedValueFromString(a, hwsSnsTrailParam).toString()?.toUpperCase();
                var textB = getNestedValueFromString(b, hwsSnsTrailParam).toString()?.toUpperCase();
                if (hwsSnsTrailDirection === 'ascending') {
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                } else {
                    return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
                };
            });
            return state;
        case FILTER_HWS_SNS_TRAIL:
            if (!state.hwsSnsTrail) {
                state.hwsSnsTrail = [];
            };
            let filterHwsSnsTrailParams = action.data;
            let filteredHwsSnsTrail = [];

            let newHwsSnsTrail = state.hwsSnsTrail.filter(m => {
                if (filterHwsSnsTrailParams?.serialNumbers?.length > 0) {
                    for (let serialNumber of filterHwsSnsTrailParams?.serialNumbers) {
                        if (!m?.serial_number_hws_in.startsWith(serialNumber)) {
                            return false;
                        };
                    };
                };
                if (filterHwsSnsTrailParams?.partNumbers?.length > 0) {
                    for (let partNumber of filterHwsSnsTrailParams?.partNumbers) {
                        if (!m?.part_number_hws_in.startsWith(partNumber)) {
                            return false;
                        };
                    };
                };
                if (filterHwsSnsTrailParams?.soldTos?.length > 0) {
                    for (let soldTo of filterHwsSnsTrailParams?.soldTos) {
                        if (!m?.applicant_id_hws_in.startsWith(soldTo)) {
                            return false;
                        };
                    };
                };
                if (filterHwsSnsTrailParams?.businessPartners?.length > 0) {
                    for (let businessPartner of filterHwsSnsTrailParams?.businessPartners) {
                        if (!m?.business_partner_hws_in.startsWith(businessPartner)) {
                            return false;
                        };
                    };
                };
                if (filterHwsSnsTrailParams?.supportPartners?.length > 0) {
                    for (let supportPartner of filterHwsSnsTrailParams?.supportPartners) {
                        if (!m?.support_partner_hws_in.startsWith(supportPartner)) {
                            return false;
                        };
                    };
                };
                if (filterHwsSnsTrailParams?.endCustomerIds?.length > 0) {
                    for (let endCustomerId of filterHwsSnsTrailParams?.endCustomerIds) {
                        if (!m?.end_customer_hws_in.startsWith(endCustomerId)) {
                            return false;
                        };
                    };
                };
                if (filterHwsSnsTrailParams?.bundleReferences?.length > 0) {
                    for (let bundleReference of filterHwsSnsTrailParams?.bundleReferences) {
                        if (!m?.bundle_reference_hws_in.startsWith(bundleReference)) {
                            return false;
                        };
                    };
                };
                if (filterHwsSnsTrailParams?.orderId?.length > 0) {
                    for (let orderId of filterHwsSnsTrailParams?.orderId) {
                        if (!m?.order_id.startsWith(orderId)) {
                            return false;
                        };
                    };
                };
                if (filterHwsSnsTrailParams?.orderLine?.length > 0) {
                    for (let orderLine of filterHwsSnsTrailParams?.orderLine) {
                        if (!m?.order_line.startsWith(orderLine)) {
                            return false;
                        };
                    };
                };
                if (filterHwsSnsTrailParams?.filterCreationDateStart) {
                    if (!moment(filterHwsSnsTrailParams.filterCreationDateStart).isBefore(moment(m.hws_in_arrival_date))) {
                        return false
                    }
                };
                return true
            });
            filteredHwsSnsTrail = filteredHwsSnsTrail.concat(newHwsSnsTrail)

            state = Object.assign({}, state, { filteredHwsSnsTrail });
            return state;
        case FILTER_SERIAL_NUMBERS:
            if (!state.serialNumbers) {
                state.serialNumbers = [];
            };
            let filterSerialNumberParams = action.data;
            let filteredSerialNumbers = [];

            let newSerialNumbers = state.serialNumbers.filter(m => {
                if (filterSerialNumberParams?.serialNumbers?.length > 0) {
                    for (let serialNumber of filterSerialNumberParams?.serialNumbers) {
                        if (!m?.serial_number.startsWith(serialNumber)) {
                            return false;
                        };
                    };
                };
                if (filterSerialNumberParams?.partNumbers?.length > 0) {
                    for (let partNumber of filterSerialNumberParams?.partNumbers) {
                        if (!m?.part_number.startsWith(partNumber)) {
                            return false;
                        };
                    };
                };
                if (filterSerialNumberParams?.soldTos?.length > 0) {
                    for (let soldTo of filterSerialNumberParams?.soldTos) {
                        if (!m?.sold_to.startsWith(soldTo)) {
                            return false;
                        };
                    };
                };
                if (filterSerialNumberParams?.orderId?.length > 0) {
                    for (let orderId of filterSerialNumberParams?.orderId) {
                        if (!m?.order_id.startsWith(orderId)) {
                            return false;
                        };
                    };
                };
                if (filterSerialNumberParams?.orderLine?.length > 0) {
                    for (let orderLine of filterSerialNumberParams?.orderLine) {
                        if (!m?.order_line.startsWith(orderLine)) {
                            return false;
                        };
                    };
                };
                if (filterSerialNumberParams?.filterShippingDateStart) {
                    if (!moment(filterSerialNumberParams.filterShippingDateStart).isBefore(moment(m.shipping_date))) {
                        return false
                    }
                };
                if (filterSerialNumberParams?.filterShippingDateEnd) {
                    if (!moment(filterSerialNumberParams.filterShippingDateEnd).isAfter(moment(m.shipping_date))) {
                        return false
                    }
                };
                if (filterSerialNumberParams?.filterExtractionDateStart) {
                    if (!moment(filterSerialNumberParams.filterExtractionDateStart).isBefore(moment(m.extraction_date))) {
                        return false
                    }
                };
                if (filterSerialNumberParams?.filterExtractionDateEnd) {
                    if (!moment(filterSerialNumberParams.filterExtractionDateEnd).isAfter(moment(m.extraction_date))) {
                        return false
                    }
                };
                return true
            });
            filteredSerialNumbers = filteredSerialNumbers.concat(newSerialNumbers)

            state = Object.assign({}, state, { filteredSerialNumbers });
            return state;
        case FILTER_DATA_QUALITY_SAP_ORDERS:
            if (!state.dataQualitySapOrders) {
                state.dataQualitySapOrders = [];
            };
            let filterDataQualitySapOrdersParams = action.data;
            let filteredDataQualitySapOrders = [];
            let newDataQualitySapOrders = state.dataQualitySapOrders.filter(m => {
                if (filterDataQualitySapOrdersParams?.partNumbers?.length > 0) {
                    for (let partNumber of filterDataQualitySapOrdersParams?.partNumbers) {
                        if (!m?.part_number.startsWith(partNumber)) {
                            return false;
                        };
                    };
                };
                if (filterDataQualitySapOrdersParams?.soldTos?.length > 0) {
                    for (let soldTo of filterDataQualitySapOrdersParams?.soldTos) {
                        if (!m?.sold_to.startsWith(soldTo)) {
                            return false;
                        };
                    };
                };
                if (filterDataQualitySapOrdersParams?.status?.length > 0) {
                    for (let status of filterDataQualitySapOrdersParams?.status) {
                        if (!m?.status?.startsWith(status)) {
                            return false;
                        };
                    };
                };
                if (filterDataQualitySapOrdersParams?.orderId?.length > 0) {
                    for (let orderId of filterDataQualitySapOrdersParams?.orderId) {
                        if (!m?.order_id.startsWith(orderId)) {
                            return false;
                        };
                    };
                };
                if (filterDataQualitySapOrdersParams?.orderLine?.length > 0) {
                    for (let orderLine of filterDataQualitySapOrdersParams?.orderLine) {
                        if (!m?.order_line.startsWith(orderLine)) {
                            return false;
                        };
                    };
                };
                if (filterDataQualitySapOrdersParams?.filterCreationDateStart) {
                    if (!moment(filterDataQualitySapOrdersParams.filterCreationDateStart).isBefore(moment(m.creation_date))) {
                        return false
                    }
                };
                if (filterDataQualitySapOrdersParams?.filterCreationDateEnd) {
                    if (!moment(filterDataQualitySapOrdersParams.filterCreationDateEnd).isAfter(moment(m.creation_date))) {
                        return false
                    }
                };
                return true
            });
            filteredDataQualitySapOrders = filteredDataQualitySapOrders.concat(newDataQualitySapOrders)
            state = Object.assign({}, state, { filteredDataQualitySapOrders });
            return state;

        case GET_ORDERS:
            state = Object.assign({}, state, { orders: action.orders, filteredOrders: action.orders });
            return state;
        case GET_SERIAL_NUMBERS:
            state = Object.assign({}, state, { serialNumbers: action.serialNumbers, filteredSerialNumbers: action.serialNumbers });
            return state;
        case GET_DATA_QUALITY_SAP_ORDERS:
            state = Object.assign({}, state, { dataQualitySapOrders: action.dataQualitySapOrders, filteredDataQualitySapOrders: action.dataQualitySapOrders });
            return state;
        case GET_HWS_SNS_TRAIL:
            state = Object.assign({}, state, { hwsSnsTrail: action.hwsSnsTrail, filteredHwsSnsTrail: action.hwsSnsTrail });
            return state;
        case GET_HWS_SNS_TRAIL_BULK:
            if (action.errors) {
                console.log("ERROR", action.errors);
                state = Object.assign({}, state);
                return state;
            }
            if (action.bulkResponse) {
                const parsedHwsSnsTrail = Papa.unparse(action.bulkResponse, { header: true });
                const encodedHwsSnsTrail = new TextEncoder().encode(parsedHwsSnsTrail);
                const hwsSnsTrailBlob = new Blob([encodedHwsSnsTrail], { type: 'text/csv' });
                downloadBulkResult(hwsSnsTrailBlob, uuidv4(), '.csv');
            }
            state = Object.assign({}, state);
            return state;
        case EXPORT_SERIAL_NUMBERS_TO_CSV:
            state = Object.assign({}, state, { CSVSerialNumbers: action.CSVSerialNumbers });
            download(uuidv4() + ".csv", action.CSVSerialNumbers);
            return state;
        case EXPORT_DATA_QUALITY_SAP_ORDERS_TO_CSV:
            state = Object.assign({}, state, { CSVDataQualityOrders: action.CSVDataQualityOrders });
            download(uuidv4() + ".csv", action.CSVDataQualityOrders);
            return state;
        case EXPORT_ORDERS_TO_CSV:
            state = Object.assign({}, state, { CSVOrders: action.CSVOrders });
            download(uuidv4() + ".csv", action.CSVOrders);
            return state;
        case EXPORT_HWS_SWAP_SNS_TO_CSV:
            state = Object.assign({}, state, { CSVHwsSwapSns: action.CSVHwsSwapSns });
            download(uuidv4() + ".csv", action.CSVHwsSwapSns);
            return state;
        case GET_DATA_QUALITY_SAP_ORDERS_BULK:
            if (action.errors) {
                console.log("ERROR", action.errors);
                state = Object.assign({}, state);
                return state;
            }
            if (action.bulkResponse) {
                const parsedDataQualitySapOrders = Papa.unparse(action.bulkResponse, { header: true });
                const encodedDataQualitySapOrders = new TextEncoder().encode(parsedDataQualitySapOrders);
                const dataQualitySapOrdersBlob = new Blob([encodedDataQualitySapOrders], { type: 'text/csv' });
                downloadBulkResult(dataQualitySapOrdersBlob, uuidv4(), '.csv');
            }
            state = Object.assign({}, state);
            return state;
        default:
            return state;
    }
    return state;
}

export default dynamoReducer