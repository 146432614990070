import {
    GET_MATERIALS,
    GET_MATERIAL_DETAILS,
    FILTER_MATERIALS,
    SORT_MATERIALS,
    GET_MATERIALS_BULK,
    EXPORT_MATERIALS_TO_CSV,
    EXPORT_MATERIALS_CONTRACTS_TO_CSV,
    GET_CANDIDATE_MATERIALS,
} from '../actions';
import { LOADING } from '../../global/actions'

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import Papa from 'papaparse';
import { download, getNestedValueFromString, downloadBulkResult } from '../../global/reducer';

const initialState = {
    parameters: {
        materials: {},
        contracts: {},
        orders: {},
        placeholderMaterials: {},
        serialNumbers: {},
        systems: {},
        systemApplications: {},
        licenses: {},
        dataQualitySapOrders: {},
        hwsSnsTrail: {}
    },
    filteredMaterials: []
};

function materialReducer(state = initialState, action) {
    if (action?.type !== "SET_USER_SESSION") {
        console.log("reducer action", action);
    };
    switch (action.type) {
        case LOADING:
            break;
        default:
            state.loading = false;
            break;
    };
    if (state.errors) {
        delete state.errors;
    };
    state.errors = action.errors;

    switch (action.type) {
        case GET_MATERIALS:
            if (!state.materials) {
                state.materials = [];
            };
            state = Object.assign({}, state, { materials: action.results, filteredMaterials: action.results });
            return state;
        case GET_MATERIAL_DETAILS:
            if (!state.materials) {
                state.materials = [];
            };
            if (!state.contracts) {
                state.contracts = [];
            };
            if (!state.placeholderMaterials) {
                state.placeholderMaterials = [];
            };
            let material;
            if (action.results && action.results.length) {
                material = action.results[0];
                if (material?.entitlements) {
                    const contractsSet = state.contracts instanceof Set ? state.contracts : new Set(state.contracts);
                    for (let contract of material.entitlements) {
                        let contractIdentifier = contract.contract_reference + contract.type
                        contract.contract_identifier = contractIdentifier;
                        contractsSet.add(contract);
                    }
                    state.contracts = Array.from(contractsSet);
                }
                if (action.results["serialNumberReference"]) {
                    material.serialNumberReference = action.results["serialNumberReference"];
                };
                if (action.results["order"]) {
                    material.order = {
                        ...action.results["order"],
                        ...material.order
                    }
                };
                if (action.data.placeholder === true) {
                    state.placeholderMaterials = state.placeholderMaterials?.filter(m => m.serial_number !== material.serial_number);
                    state.placeholderMaterials.push(material);
                } else {
                    state.materials = state.materials.filter(m => m.serial_number !== material.serial_number);
                    state.materials.push(material);
                }
            };
            state = Object.assign({}, state, { loading: false });
            return state;
        case FILTER_MATERIALS:
            if (!state.materials) {
                state.materials = [];
            };
            let filterMaterialParams = action.data;
            let filteredMaterials = [];

            let newMaterials = state.materials.filter(m => {
                if (filterMaterialParams?.serialNumbers?.length > 0) {
                    for (let serialNumber of filterMaterialParams?.serialNumbers) {
                        if (!m?.serial_number.startsWith(serialNumber)) {
                            return false;
                        };
                    };
                };
                if (filterMaterialParams?.partNumbers?.length > 0) {
                    for (let partNumber of filterMaterialParams?.partNumbers) {
                        if (!m?.part_number.startsWith(partNumber)) {
                            return false;
                        };
                    };
                }
                if (filterMaterialParams?.soldTos?.length > 0) {
                    for (let soldTo of filterMaterialParams?.soldTos) {
                        if (!m?.sold_to?.applicant_id.startsWith(soldTo)) {
                            return false;
                        };
                    };
                }
                if (filterMaterialParams?.businessPartners?.length > 0) {
                    for (let businessPartner of filterMaterialParams?.businessPartners) {
                        if (!m?.business_partner?.company_id.startsWith(businessPartner)) {
                            return false;
                        };
                    };
                }
                if (filterMaterialParams?.orderIds?.length > 0) {
                    for (let orderId of filterMaterialParams?.orderIds) {
                        if (!m?.order?.order_id.startsWith(orderId)) {
                            return false;
                        };
                    };
                }
                if (filterMaterialParams?.filterCreationDateStart) {
                    if (!moment(filterMaterialParams.filterCreationDateStart).isBefore(moment(m.creation_date))) {
                        return false
                    }
                };
                if (filterMaterialParams?.filterCreationDateEnd) {
                    if (!moment(filterMaterialParams.filterCreationDateEnd).isAfter(moment(m.creation_date))) {
                        return false
                    }
                };
                if (filterMaterialParams?.filterModificationDateStart) {
                    if (!moment(filterMaterialParams.filterModificationDateStart).isBefore(moment(m.modification_date))) {
                        return false
                    }
                };
                if (filterMaterialParams?.filterModificationDateEnd) {
                    if (!moment(filterMaterialParams.filterModificationDateEnd).isAfter(moment(m.modification_date))) {
                        return false
                    }
                };
                return true
            });
            filteredMaterials = filteredMaterials.concat(newMaterials)

            state = Object.assign({}, state, { filteredMaterials });
            console.log('filteredMaterials', filteredMaterials);
            return state;

        case EXPORT_MATERIALS_TO_CSV:
            console.log("EXPORT_MATERIALS_TO_CSV", action.CSVMaterials);
            state = Object.assign({}, state, { CSVMaterials: action.CSVMaterials });
            download(uuidv4() + ".csv", action.CSVMaterials);
            return state;
        case EXPORT_MATERIALS_CONTRACTS_TO_CSV:
            console.log("EXPORT_MATERIALS_CONTRACTS_TO_CSV", action.CSVMaterials);
            state = Object.assign({}, state, { CSVMaterials: action.CSVMaterials });
            download(uuidv4() + ".csv", action.CSVMaterials);
            return state;
        case SORT_MATERIALS:
            console.log("SORT_MATERIALS", action.data);
            const materialParam = action.data.sortParameter;
            const materialDirection = action.data.direction;

            // This makes a direct modification to the state, so no Object.assign is required. Somehow.
            state?.filteredMaterials?.sort(function (a, b) {
                var textA = getNestedValueFromString(a, materialParam).toString()?.toUpperCase();
                var textB = getNestedValueFromString(b, materialParam).toString()?.toUpperCase();
                if (materialDirection === 'ascending') {
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                } else {
                    return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
                };
            });
            return state;

        case GET_MATERIALS_BULK:
            if (action.bulkResponse) {
                const parsedMaterials = Papa.unparse(action.bulkResponse, { header: true });
                const encodedMaterials = new TextEncoder().encode(parsedMaterials);
                const materialsBlob = new Blob([encodedMaterials], { type: 'text/csv' });
                downloadBulkResult(materialsBlob, uuidv4(), '.csv');
            }
            state = Object.assign({}, state);
            return state;

        case GET_CANDIDATE_MATERIALS:
            state = Object.assign({}, state, { candidateMaterials: action.candidateMaterials });
            return state;
    }
    return state;
}

export default materialReducer
