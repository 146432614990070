export const LOADING = 'LOADING';
export const ERROR = 'ERROR';
export const SET_USER_SESSION = 'SET_USER_SESSION';
export const SET_PARAMETERS = 'SET_PARAMETERS';
export const SET_DATE_PARAMETERS = 'SET_DATE_PARAMETERS';
export const REMOVE_PARAMETER = 'REMOVE_PARAMETER';
export const CLEAR_PARAMETER = 'CLEAR_PARAMETER';


export function setUserSession(userSession) {
    console.log("Action SET_USER_SESSION");
    return { type: SET_USER_SESSION, userSession };
}

export function setParameters(parameters) {
    console.log("Action SET_PARAMETERS", parameters);
    return { type: SET_PARAMETERS, data: parameters };
};

export function setDateParameters(parameters) {
    console.log("Action SET_DATE_PARAMETERS", parameters);
    return { type: SET_DATE_PARAMETERS, data: parameters };
};
export function removeParameter(parameter) {
    console.log("Action REMOVE_PARAMETER", parameter);
    return { type: REMOVE_PARAMETER, data: parameter };
};

export function clearParameters(type) {
    console.log("Action CLEAR_PARAMETER", type);
    return { type: CLEAR_PARAMETER, data: type };
};