import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useDispatch } from 'react-redux';

export default function SearchDateInput(props) {
    const dispatch = useDispatch();
    const family = props?.family;

    const handleDispatch = (e, type) => {
        dispatch(props.setFilterDate({
            [family]: { [type]: e.target.value }
        }))
    }

    return (
        <Grid container direction={"row"} style={{ alignItems: 'center', paddingLeft: '1%' }}>
            <Grid>
                <Typography style={{ color: 'grey' }}>
                    {props.title}&nbsp;
                </Typography>
            </Grid>
            <Grid item>
                <Typography>
                    From:&nbsp;
                </Typography>
            </Grid>
            <Grid item style={{ paddingBottom: '1%' }}>
                <input type="date" id="start" name="start" onChange={(e) => { handleDispatch(e, 'creationDateStart') }} />
            </Grid>
            <div style={{ paddingLeft: '1%' }} />
            <Grid item>
                <Typography>
                    To:&nbsp;
                </Typography>
            </Grid>
            <Grid item style={{ paddingBottom: '1%' }}>
                <input type="date" id="end" name="end" onChange={(e) => { handleDispatch(e, 'creationDateEnd') }} />
            </Grid>
            <div style={{ paddingLeft: '1%' }} />
            <Grid item>
                <Typography style={{ fontSize: '0.4em' }}>
                    (both fields are required)
                </Typography>
            </Grid>
        </Grid>
    );
}
